@import "~bootstrap/scss/bootstrap.scss";

.sidebar {
  max-width: 230px;
  min-width: 230px;
  min-height: 100vh;
  background: #f3f3f3;
  transition: all 0.3s;
}

.sidebar a {
  color: #7c7c7c;
}

.sidebar a:hover {
  color: #002c5b;
  text-decoration: none;
}

.sidebar-title {
  padding: 10px;
  margin: 0;
  font-size: 1.6rem;
}

.sidebar-title a {
  color: inherit;
  text-decoration: none;
  word-break: break-all;
}

.sidebar-title a:hover {
  color: inherit;
  text-decoration: none;
  word-break: break-all;
}

.sidebar-menu__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.sidebar-menu__item {
  line-height: 2.5rem;
}

.sidebar-menu__item a {
  display: flex;
  padding-left: 15px;
}

.sidebar-menu__item--active {
  box-shadow: #004999 4px 0px 0px 0px inset;
}

.sidebar-submenu__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebar-submenu__item a {
  display: flex;
  padding-left: 32px;
}

.sidebar-submenu__item--active {
  background-color: #dbdbdb;
  box-shadow: #004999 4px 0px 0px 0px inset;
  font-weight: bold;
}

.sidebar-submenu__item--active a {
  color: #002c5b;
}

.form-group.required label:after {
  content: "*";
  color: red;
}

.form-text {
  font-size: 0.85rem;
}

.display-1 a {
  color: inherit;
  text-decoration: none;
}

.display-1 a:hover {
  color: inherit;
  text-decoration: none;
}

i[data-fa-i2svg] {
  margin-right: 0.3rem;
}

.wiki-footer {
  border-top: 1px solid #999999;
  font-size: 85%;
}

.wiki-main {
  h3 {
    margin-top: 1.8rem;
    margin-bottom: 1rem;
  }

  th,
  td {
    border: 1px solid #dee2e6;
    padding: 0.8rem;
  }

  table {
    @extend .table;
    @extend .table-striped;
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0.8rem;
  }

  .toc {
    @extend .card;
    @extend .card-body;
    ul {
      list-style-type: none;
    }
  }
}

.form-group > input[type="checkbox"] {
  width: 2em;
  font-size: 0.75em;
}
